<template>
    <v-dialog v-model="dialog" max-width="600">
    <v-card>
        <v-card-title class="headline"
        >Validating Virtual Monitor</v-card-title
        >
        <v-card-text>
        <v-stepper v-model="vSteps" vertical>
            <!--Validate Name Step-->
            <v-stepper-step
            :complete="vSteps > 1 && isValidVirtualMonitorName"
            step="1"
            color="green"
            >
            Validating Virtual Monitor Name
            <small
                v-if="vSteps > 1 && isValidVirtualMonitorName === true"
                >{{"Virtual Monitor Name is valid"}}</small
            >
            <v-row
                style="width: 500px"
                v-if="vSteps > 1 && isValidVirtualMonitorName === false"
            >
                <v-col class="pt-0" cols="2" md="9" sm="9">
                <small v-if="vSteps > 2 && isValidVirtualMonitorName === false">{{"You have a duplicate Equipment name"}}</small>
                </v-col>
                <v-col cols="auto" md="3" sm="3">
                <v-btn
                    class="mt-n7"
                    color="primary"
                    title="You have a duplicate Equipment name"
                    @click="fixMeterErrors(1)"
                    >Fix</v-btn
                >
                </v-col>
            </v-row>
            </v-stepper-step>

            <v-stepper-content step="1">
            <v-progress-linear
                indeterminate
                color="green"
            ></v-progress-linear>
            </v-stepper-content>
            <!--Validate Friendly Name Step-->
            <v-stepper-step
            :complete="vSteps > 2 && isValidFriendlyName === true"
            step="2"
            color="green"
            >
            Validating Display Name
            <small v-if="vSteps > 2 && isValidFriendlyName === true">{{"Display Name is valid"}}</small>
            <v-row
                style="width: 500px"
                v-if="vSteps > 2 && isValidFriendlyName === false"
            >
                <v-col class="pt-0" cols="2" md="9" sm="9">
                <small v-if="vSteps > 3">{{"Enter a unique display name"}}</small>
                </v-col>
                <v-col cols="auto" md="3" sm="3">
                <v-btn
                    class="mt-n7"
                    color="primary"
                    title="Enter a unique display name"
                    @click="fixmeterErrors(2)"
                    >Fix</v-btn
                >
                </v-col>
            </v-row>
            </v-stepper-step>
            <v-stepper-content step="2">
            <v-progress-linear
                indeterminate
                color="green"
            ></v-progress-linear>
            </v-stepper-content>
            <!--Validate Site Location Step-->
            <v-stepper-step
            :complete="vSteps > 3 && isValidSiteLocation === true"
            step="3"
            color="green"
            >
            Validating Site Location
            <small v-if="vSteps > 3 && isValidSiteLocation === true">{{"Site Location is valid"}}</small>
            <v-row
                style="width: 500px"
                v-if="vSteps > 3 && isValidSiteLocation === false"
            >
                <v-col class="pt-0" cols="2" md="9" sm="9">
                <small v-if="vSteps > 4">{{"Site location must be selected"}}</small>
                </v-col>
                <v-col cols="auto" md="3" sm="3">
                <v-btn
                    class="mt-n7"
                    color="primary"
                    title="Site location must be selected"
                    @click="fixMeterErrors(3)"
                    >Fix</v-btn
                >
                </v-col>
            </v-row>
            </v-stepper-step>
            <v-stepper-content step="3">
            <v-progress-linear
                indeterminate
                color="green"
            ></v-progress-linear>
            </v-stepper-content>
        </v-stepper>
        </v-card-text>
        <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn color="green darken-1" text @click="cancelDialog"
            >Cancel</v-btn
        >
        <v-btn
            color="green darken-1"
            :disabled="!isValidVirtualMonitor || invalid"
            text
            @click="dismissDialog"
            >Continue</v-btn
        >
        </v-card-actions>
    </v-card>
    </v-dialog>
</template>

<script>
import { mixin as VueTimers } from "vue-timers";

export default {
    components: {
    },

    timers: {
        metaValidator: { time: 1000, autostart: false, repeat: true },
    },

    mixins: [VueTimers],

    props: {
        invalid: { type: Boolean, default: () => true },
        monitorName: { type: String, default: () => null },
        friendlyName: { type: String, default: () => null },
        siteLocation: { type: Object, default: () => null },
    },

    data() {
        return {
            dialog: false,
            vSteps: 0,
            isValidVirtualMonitor: false,
            isValidVirtualMonitorName: false,
            isValidFriendlyName: false,
            isValidSiteLocation: false
        };
    },

    async created() {
    },

    methods: {
        validateMeter() {
            this.isValidVirtualMonitorName = false;
            this.isValidFriendlyName = false;
            this.isValidSiteLocation = false;
            this.dialog = true;
            this.$timer.start("metaValidator");
        },

        metaValidator() {
            this.vSteps++;

            if (this.vSteps == 1) {
                // compare proposed name to cached list of endUses for site
                this.isValidVirtualMonitorName = true;
            }
            if (this.vSteps == 2) {
                // Friendly Name must be input
                this.isValidFriendlyName = true;
            }

            if (this.vSteps == 3) {
                // Site Location must be selected
                this.isValidSiteLocation = this.siteLocation !== null;
            }

            if (this.vSteps > 4) {
                this.$timer.stop("metaValidator");
                this.isValidating = false;
            }

            this.isValidVirtualMonitor =
                this.isValidVirtualMonitorName && 
                this.isValidFriendlyName &&
                this.isValidSiteLocation;
        },

        fixMeterErrors(item) {
            this.cancelDialog();
            this.$emit("fixmeter", item);
        },

      cancelDialog() {
        this.dialog = false;
        this.$timer.stop("metaValidator");
        this.vSteps = 0;
      },

      dismissDialog() {
        this.cancelDialog();
        this.$emit("dismissdialog", this.isValidVirtualMonitor);
      },

    }
};
</script>
